import React from 'react';
import GetProducts from '../Components/Shop/getProducts';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProductCards from '../Components/Shop/productCards';
import "./shop.css";


export default function Shop() {
    let products = GetProducts();
    if (products == null) {
        return <h2>Products are loading . . .</h2>
    }
    else {
        return (
            <Container fluid className="shop-container">
                <br></br>
                <Row>
                    {products.map((product, i) => (
                        <>
                            {product.specialPrice == null ? (
                                <Col className="cardCol" key={i}>

                                    <ProductCards product={product} />
                                </Col>
                            ) : (
                                null
                            )}
                        </>
                    ))}
                </Row>
            </Container >
        )
    }
}
